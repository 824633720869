@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/gilroy/Gilroy-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/gilroy/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/gilroy/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

*{
  font-family: "Gilroy" !important;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

a{
  text-decoration: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.overflow-y{
  overflow-y: scroll;
}

.overflow-y::-webkit-scrollbar {
  display: none;
}

.hide_scroll::-webkit-scrollbar {
  display: none;
}

.gradient-animation {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #eee;
  background: linear-gradient(90deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 1000px 640px;
  position: relative;
}

@keyframes placeHolderShimmer{
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}